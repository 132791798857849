<template>
  <div class="video-container">
    <slot></slot>
    <div v-show="isLoading" class="prism-player loading-player">
      <div
        class="prism-controlbar"
        style="position: absolute; left: 0px; bottom: 0px; display: block"
      >
        <div class="prism-controlbar-bg"></div>
        <div
          class="prism-progress"
          style="position: absolute; left: 0px; bottom: 44px"
        >
          <div class="prism-progress-marker"></div>
        </div>
        <div
          class="prism-play-btn"
          style="float: left; margin-left: 20px; margin-top: 12px"
        ></div>
        <div
          class="prism-time-display"
          style="float: left; margin-left: 10px; margin-top: 7px"
        ></div>
        <div
          class="prism-setting-btn"
          style="float: right; margin-right: 20px; margin-top: 12px"
        ></div>
        <div
          class="prism-volume"
          style="float: right; margin-right: 5px; margin-top: 10px"
        >
          <div class="volume-icon">
            <div class="short-horizontal"></div>
            <div class="long-horizontal"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="player-con" class="prism-player"></div>
  </div>
</template>

<script>
import hjjService from '@/services/hjj';

export default {
  props: {
    videoId: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: 'tsume',
    },
  },
  data() {
    return {
      player: null,
      playAuth: {},
      isLoading: true,
    };
  },
  watch: {
    async videoId() {
      await this.init();
    },
    isLoading: {
      immediate: true,
      handler() {
        this.$store.commit('env/setIsLoading', this.isLoading);
      },
    },
  },

  async mounted() {
    this.init();
  },
  async beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async init() {
      if (this.videoId != null) {
        if (this.mode === 'complete') {
          this.playAuth = await hjjService.getCourseCompletionVideoAuth(
            this.videoId
          );
        } else {
          this.playAuth = await hjjService.getTsumeVideoPlayAuth(this.videoId);
        }
      }

      // eslint-disable-next-line no-undef
      this.player = new Aliplayer({
        id: 'player-con',
        vid: this.videoId,
        playauth: this.playAuth.PlayAuth,
        qualitySort: 'asc',
        mediaType: 'video',
        width: '100%',
        height: '100%',
        autoplay: true,
        isLive: false,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: 'click',
        showBarTime: 4000,
        useH5Prism: true,
        language: 'en-us',
        skinLayout: [
          {
            name: 'bigPlayButton',
            align: 'cc',
          },
          {
            name: 'errorDisplay',
            align: 'tlabs',
            x: 0,
            y: 0,
          },
          {
            name: 'infoDisplay',
          },
          {
            name: 'tooltip',
            align: 'blabs',
            x: 0,
            y: 56,
          },
          {
            name: 'controlBar',
            align: 'blabs',
            x: 0,
            y: 0,
            children: [
              {
                name: 'progress',
                align: 'blabs',
                x: 0,
                y: 44,
              },
              {
                name: 'playButton',
                align: 'tl',
                x: 20,
                y: 12,
              },
              {
                name: 'timeDisplay',
                align: 'tl',
                x: 10,
                y: 7,
              },
              {
                name: 'setting',
                align: 'tr',
                x: 20,
                y: 12,
              },
              {
                name: 'volume',
                align: 'tr',
                x: 5,
                y: 10,
              },
            ],
          },
        ],
      });

      this.player.on('ready', () => {
        this.isLoading = false;
      });
      this.player.on('timeupdate', () => {
        this.$emit('onTimeChange', this.player.getCurrentTime());
      });
      this.player.on('showBar', () => {
        this.$emit('onControllerShow');
      });
      this.player.on('hideBar', () => {
        this.$emit('onControllerHide');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  width: 100%;
  height: 100%;
  background: black;
  .loading-player {
    height: 100%;
  }
}
</style>
