<template>
  <div class="wrap-tsume-answer h-100">
    <base-header
      :title="questions[questionIndex].type"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :left-text="!$device.isMobile ? '返回首頁' : ''"
      :back-on-click-left="false"
      @on-click-left="goBack"
    ></base-header>
    <div
      class="tsume-answer px-3 pt-2 scroll-bar d-flex flex-column justify-content-center align-items-center p-md-3"
    >
      <div
        class="d-flex flex-column flex-md-row-reverse justify-content-md-center align-items-center w-100 h-100"
      >
        <div
          class="wrap-operate-panel ml-md-3"
          :class="{'bg-white': !$device.isMobile}"
        >
          <div
            class="operate-panel d-md-flex flex-md-column pt-md-2"
            :class="{'scroll-bar': !$device.isMobile}"
          >
            <b-row
              v-if="$device.isMobile"
              class="wrap-question w-100 m-0 mb-2 mb-md-0 px-md-2"
            >
              <b-col cols="12" class="p-0 card-question">
                <b-card
                  border-variant="white"
                  class="card-shadow position-relative"
                >
                  <div class="d-flex flex-nowrap align-items-center">
                    <div
                      class="wrap-question-count ml-1 my-1 mr-3 d-flex flex-column align-items-center"
                      :class="$isJungo ? 'bg-primary' : 'bg-secondary'"
                    >
                      <p class="text-20 text-center m-0">
                        {{ questionIndex + 1 }}
                      </p>
                      <p class="text-12 text-center m-0">
                        {{ $t('共') }}{{ questions.length }}{{ $t('題') }}
                      </p>
                    </div>
                    <div class="wrap-question-details d-flex flex-fill">
                      <p ref="questionTitle" class="question m-0 w-100">
                        {{ questions[questionIndex].prompt }}
                      </p>
                      <div
                        class="tsume-content flex-fill d-flex flex-column justify-content-center align-items-center"
                      >
                        <p
                          v-if="questions[questionIndex].isCorrect"
                          class="iscorrect font-weight-bold m-0 d-flex justify-content-center align-items-center"
                        >
                          <span class="icon-Circle-check mr-3"> </span>
                        </p>
                        <p
                          v-else
                          class="iscorrect font-weight-bold m-0 d-flex justify-content-center align-items-center"
                        >
                          <span class="icon-Circle-X mr-3"> </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row
              v-for="(question, index) in questions"
              v-else
              :key="index"
              class="wrap-question d-flex py-2 pl-3 p-md-0 justify-content-between align-items-center mx-md-2 mb-md-2"
              :class="{active: index === questionIndex}"
            >
              <div
                class="wrap-check-answer d-flex"
                @click.stop="onClickQuestion(index)"
              >
                <div
                  class="tsume-content d-flex flex-column justify-content-center align-items-start ml-0 w-100"
                >
                  <div
                    class="wrap-top d-flex flex-row justify-content-center align-items-center"
                  >
                    <p
                      class="question-count d-flex flex-column align-items-center m-0 mr-2 text-16"
                      :class="
                        question.isCorrect ? 'bg-positive' : 'bg-negative'
                      "
                    >
                      <span>{{ index + 1 }}</span>
                      <span
                        :class="question.isCorrect ? 'icon-Check' : 'icon-X'"
                      ></span>
                    </p>
                    <p class="anwer-title m-0 text-14">
                      {{ question.prompt }}
                    </p>
                  </div>
                  <div
                    v-show="index === questionIndex"
                    class="wrap-bottom mt-3 w-100"
                  >
                    <button
                      class="btn btn-white mr-2"
                      :class="{active: selectedQuestionTab === 'myAnswer'}"
                      @click.stop="onClickQuestionTab('myAnswer')"
                    >
                      {{ $t('我的答案') }}
                    </button>
                    <button
                      class="btn btn-white"
                      :class="{active: selectedQuestionTab === 'question'}"
                      @click.stop="onClickQuestionTab('question')"
                    >
                      {{ $t('查看解答') }}
                    </button>
                  </div>
                  <div
                    v-show="
                      index === questionIndex &&
                      selectedQuestionTab === 'question'
                    "
                    class="wrap-current-answer w-100"
                  >
                    <hr />
                    <button
                      v-if="mode === 'courseTsume' && question.videoId"
                      class="btn btn-white"
                      @click.stop="onClickVideo(question.videoId)"
                    >
                      <span class="icon-Circle-play mr-1"></span
                      >{{ $t('視頻解說') }}
                    </button>
                    <button
                      v-for="(
                        currentAnswer, currentAnsweIndex
                      ) in question.currentAnswer"
                      :key="currentAnsweIndex"
                      class="btn btn-white"
                      :class="{
                        active: selectedAnswerIndex === currentAnsweIndex,
                      }"
                      @click.stop="onClickAnswer('answer', currentAnsweIndex)"
                    >
                      {{ $t('解答') + (currentAnsweIndex + 1) }}
                    </button>
                  </div>
                </div>
              </div>
            </b-row>
          </div>
        </div>
        <b-row class="chessboard m-md-0">
          <b-col cols="12" class="p-0 position-relative">
            <chessboard
              ref="chessboard"
              :key="questionIndex"
              mode="board"
              toolbar="checkAnswer"
              board-size="9"
              :sgf="questions[questionIndex].sgf"
              :question="questions[questionIndex]"
              @on-click-video="onClickVideo"
            ></chessboard>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="$device.isMobile" class="wrap-select-question py-2 px-3 d-flex">
      <b-button
        :disabled="questionIndex === 0"
        variant="primary"
        size="md"
        class="mr-2 prev-question"
        @click="prevQuestion"
      >
        {{ $t('上一題') }}
      </b-button>
      <b-button
        :disabled="questionIndex === questions.length - 1"
        variant="primary"
        size="md"
        class="next-question"
        @click="nextQuestion"
      >
        {{ $t('下一題') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import Chessboard from '@/components/Chessboard/Chessboard.vue';
import BaseHeader from '@/components/Base/BaseHeader';
// import delay from '@/lib/base/delay.js';
import {untieSgf} from 'goer-utils/function/sgf';

export default {
  name: 'TsumeCheckAnswer',
  components: {
    Chessboard,
    BaseHeader,
  },
  props: {
    questions: {
      type: Array,
      default: () => {
        return [
          {
            type: '',
          },
        ];
      },
    },
    selectedQuestion: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: 'practiceTsume',
    },
  },
  data() {
    return {
      chessboardKey: 0,
      questionIndex: this.selectedQuestion,
      selectedQuestionTab: 'myAnswer',
      currentQuestion: [],
      questionTitleHeight: 0,
      selectedAnswerIndex: 0,
    };
  },
  computed: {
    device() {
      return this.$store.state.env.device;
    },
  },
  watch: {
    questionTitleHeight(newValue) {
      if (newValue) {
        const twoLineHeight = 28 * 2;
        if (newValue > twoLineHeight) {
          this.$refs.questionTitle.classList.add('overLine');
        } else {
          this.$refs.questionTitle.classList.remove('overLine');
        }
      }
    },
  },
  mounted() {
    this.untieSgf();
    this.$refs.chessboard.checkAnswerTab(this.selectedQuestionTab);
    if (this.$device.isMobile)
      this.questionTitleHeight = this.$refs.questionTitle.scrollHeight;
  },
  methods: {
    goBack() {
      this.$device.isMobile
        ? this.$emit('on-click-back')
        : this.$router.push('/');
    },
    async prevQuestion() {
      if (this.questionIndex !== 0) this.questionIndex--;
      await this.$nextTick();
      this.$refs.chessboard.checkAnswerTab('myAnswer');
      this.questionTitleHeight = this.$refs.questionTitle.scrollHeight;
    },
    async nextQuestion() {
      if (this.questionIndex !== this.questions.length - 1)
        this.questionIndex++;
      await this.$nextTick();
      this.$refs.chessboard.checkAnswerTab('myAnswer');
      this.questionTitleHeight = this.$refs.questionTitle.scrollHeight;
    },
    onClickVideo(videoId) {
      this.$emit('on-click-video', videoId);
    },
    async onClickQuestion(index) {
      this.questionIndex = index;
      await this.$nextTick();
      this.$refs.chessboard.checkAnswerTab(this.selectedQuestionTab);
      if (this.selectedQuestionTab === 'question') {
        this.onClickAnswer('answer', 0);
      }
    },
    onClickQuestionTab(value) {
      if (value === 'answer') {
        this.$refs.chessboard.checkAnswerTab('question');
        this.selectedQuestionTab = 'question';
      } else {
        this.$refs.chessboard.checkAnswerTab(value);
        this.selectedQuestionTab = value;
        if (value === 'question') {
          this.onClickAnswer('answer', 0);
        }
      }
    },
    onClickAnswer(value, index) {
      this.selectedAnswerIndex = index;
      this.$refs.chessboard.checkAnswerTab(value, index);
    },
    untieSgf() {
      this.questions.map((question) => {
        const _question = untieSgf(question.sgf);
        question.currentAnswer = _question;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-tsume-answer {
  background: $bgsection;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1022;
  .tsume-answer {
    max-height: calc(100% - 56px - 54px);
    height: 100%;
    @media screen and (min-width: 768px) {
      max-width: 1440px;
      margin: 0 auto;
      min-height: calc(100% - 56px);
      max-height: calc(100vh - 56px);
      height: calc(100% - 56px);
    }
    .icon-Circle-check {
      font-size: 26px;
      color: $positive;
    }
    .icon-Circle-X {
      font-size: 26px;
      color: $negative;
    }
  }
  .wrap-operate-panel {
    width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 362px;
      width: 362px;
      margin: 0;
      height: 100%;
      border-radius: 10px;
      padding: 16px;
    }
    @media screen and (min-width: 1440px) {
      min-width: 523px;
      width: 523px;
    }
    .operate-panel {
      @media screen and (min-width: 768px) {
        background: $bgsection;
        height: 100%;
        border-radius: 10px;
        overflow: overlay;
      }
    }
  }
  .wrap-question {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 8px;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
    position: relative;
    &.active {
      margin: 8px 8px 16px !important;
    }
    &.active {
      .wrap-check-answer {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          background: $secondary;
          top: -8px;
          bottom: -8px;
          left: -8px;
          right: -8px;
          z-index: 0;
        }
      }
    }
    .wrap-question-details {
      .question {
        font-size: 16px;
        @media screen and (min-width: 768px) {
          line-height: 28px;
          &.overLine {
            line-height: 20px;
            font-size: 12px;
          }
        }
        &.overLine {
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .wrap-check-answer {
      width: 100%;
      &.tsume-mode {
        width: calc(100% - 44px);
        @media screen and (min-width: 768px) {
          width: 100%;
        }
      }
      .btn-white {
        width: calc(50% - 4px);
        padding: 5px 0;
      }
      .wrap-current-answer {
        .btn-white {
          width: 100%;
          &:not(:last-of-type) {
            margin-bottom: 8px;
          }
        }
        .icon-Circle-play {
          font-size: 20px;
          position: relative;
          top: 3px;
        }
      }
    }
    .tsume-content {
      @media screen and (min-width: 768px) {
        z-index: 2;
        background: white;
        border-radius: 10px;
        padding: 8px;
        flex: 0 1 auto;
      }
      .question-count {
        @media screen and (min-width: 768px) {
          min-width: 42px;
          width: 42px;
          height: 42px;
          border-radius: 10px;
          color: white;
          line-height: 16px;
          padding: 5px 6px;
        }
      }
    }
  }
  .wrap-question-count {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 10px;
    color: white;
  }
  .chessboard {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .wrap-comment-video {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
  .wrap-select-question {
    background: white;
    .btn {
      width: calc(50% - 4px);
    }
  }
  /deep/ .chessboard-wrapper {
    width: 98%;
    height: 98%;
    margin: 0 auto;
    @media screen and (min-width: 321px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: 768px) {
      width: calc(100vh - 56px - 32px - 50px - 16px);
    }
  }
}
</style>
