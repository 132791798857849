<template>
  <div ref="wrap-tsume-room" class="wrap-tsume-room h-100">
    <PassAnimation
      v-if="isPassAnimation && $device.isMobile"
      :leave="isLeave"
      mode="tsume"
      @animation-end="destoryPassAnimation"
    ></PassAnimation>
    <WebPassAnimation
      v-if="isPassAnimation && !$device.isMobile"
      :leave="isLeave"
      mode="tsume"
      @animation-end="destoryPassAnimation"
    ></WebPassAnimation>
    <base-header
      ref="header"
      class="tsume-header"
      :title="title"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :left-text="baseHeaderLeftText"
      :back-on-click-left="false"
      right-icon="Setting"
      @on-click-left="goBack"
      @on-click-right="goSetting"
    ></base-header>
    <div
      v-if="mode === 'verificationTsume' && $device.isMobile"
      class="wrap-victory-condition text-center text-12 text-white"
      :class="$isJungo ? 'bg-secondary' : 'bg-accent'"
    >
      {{ $t('限時倒數') + ' : ' }}{{ remainTime || '00:00' }}
    </div>
    <div
      class="tsume-room px-3 p-md-4 d-flex flex-column flex-md-row-reverse justify-content-center align-items-center"
      :class="{isVerificationTsume: mode === 'verificationTsume'}"
    >
      <div class="wrap-right h-100">
        <div
          class="wrap-operate-panel ml-md-3"
          :class="{'bg-white': !$device.isMobile}"
        >
          <div
            v-if="!hasPracticeTsumeList"
            class="operate-panel d-md-flex flex-md-column justify-content-md-between"
          >
            <div>
              <div
                v-if="mode === 'verificationTsume' && !$device.isMobile"
                class="wrap-victory-condition text-center text-18 text-white bg-secondary"
              >
                {{ $t('限時倒數') + ' : ' }}{{ remainTime || '00:00' }}
              </div>
              <b-row
                v-if="!isTsumeAnswerShow"
                class="wrap-question w-100 m-0 mb-2 mb-md-0 px-md-2"
              >
                <b-col cols="12" class="p-0 card-question">
                  <b-card
                    border-variant="white"
                    class="card-shadow position-relative"
                  >
                    <div class="d-flex flex-nowrap align-items-center">
                      <div
                        class="wrap-quection-count ml-1 my-1 mr-3 ml-md-2 my-md-2 d-flex flex-column align-items-center"
                        :class="$isJungo ? 'bg-primary' : 'bg-secondary'"
                      >
                        <p class="question-index text-center m-0">
                          {{ questionIndex + 1 }}
                        </p>
                        <p
                          v-if="$isJungo"
                          class="question-count text-center m-0"
                        >
                          ({{ questionIndex + 1 }}/{{ questionsCount }})
                        </p>
                        <p v-else class="question-count text-center m-0">
                          {{ $t('共') }}{{ questionsCount }}{{ $t('題') }}
                        </p>
                      </div>
                      <div class="wrap-question-details d-flex flex-fill">
                        <p
                          ref="questionTitle"
                          class="question m-0 w-100 mr-3 mr-md-2"
                        >
                          {{ questionTitle }}
                        </p>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <ChessboardToolbar
              v-if="
                !$device.isMobile && chessboardToolbarMode !== 'checkAnswer'
              "
              :mode="chessboardToolbarMode"
              @item-click="onToolbarClick"
              @move-chess="moveChess"
            ></ChessboardToolbar>
          </div>
          <TsumePracticeList
            v-if="
              hasPracticeTsumeList &&
              mode === 'practiceTsume' &&
              !$device.isMobile
            "
            @start-practice-tsume="willStartPracticeTsume = true"
          ></TsumePracticeList>
        </div>
      </div>
      <b-row class="chessboard m-md-0">
        <b-col cols="12" class="p-0 position-relative">
          <Chessboard
            ref="chessboard"
            :key="chessboardKey"
            :mode="tsumeRoomChessboardMode"
            :is-verification="mode === 'verificationTsume'"
            :board-size="tsumeData.boardSize"
            :sgf="tsumeData.sgf"
            :question="question"
            :is-tsume-start="isTsumeStart"
            :is-question-next="isQuestionNext"
            toolbar=""
            :hide-tool-bar="
              chessboardToolbarMode !== 'checkAnswer' && !$device.isMobile
            "
            :is-locked="isPlayerLocked"
            @update-tsume-question="updateTsumeQuestion"
            @chessboard-toolbar-mode-change="onChessboardToolbarModeChange"
            @is-player-lock="onPlayerLockChange"
          ></Chessboard>
        </b-col>
      </b-row>
    </div>
    <modal-result
      v-if="isModalTsumeResultShow"
      :mode="mode"
      :is-success="isWin"
      :is-over-time="isOverTime"
      :result-content="tsumeReslut"
      @item-click="onModalReultClick"
    ></modal-result>
    <modal-message-box
      v-if="isModalMessageBoxShow"
      :result-content="[$t('請記得於時間內完成'), $t('逾期將視為放棄')]"
      :buttons="messageBoxButtons"
      @on-item-click="onModalMessageBoxClick"
    >
    </modal-message-box>
    <transition name="slide" mode="out-in">
      <tsume-answer-list
        v-if="isTsumeAnswerShow && $device.isMobile"
        :questions="questions"
        :mode="mode"
        @on-click-back="onCloseTsumeAnswerList"
        @on-click-question="checkAnswer"
        @on-click-video="goVideo"
      ></tsume-answer-list>
    </transition>
    <transition :name="$device.isMobile ? 'slide' : ''" mode="out-in">
      <tsume-check-answer
        v-if="isTsumeCheckAnswerShow"
        :questions="questions"
        :mode="mode"
        :selected-question="selectedQuestion"
        @on-click-back="onCloseTsumeCheckAnswer"
        @on-click-video="goVideo"
      ></tsume-check-answer>
    </transition>
    <Video
      v-if="isCommentaryVideoShow"
      class="video position-absolute"
      :video-id="videoId"
    >
      <div class="back-btn-overlay">
        <div class="back-btn icon-Chevron-left" @click="closeVideo"></div>
      </div>
    </Video>
    <modal-wrapper
      v-if="isSettingShow && !$device.isMobile"
      :is-modal-wrapper-show="isSettingShow"
      :is-show-transition="true"
      :click-to-close="true"
      @close="isSettingShow = false"
    >
      <Setting
        v-if="isSettingShow"
        @close="isSettingShow = false"
        @click.native.stop=""
      ></Setting>
    </modal-wrapper>
  </div>
</template>

<script>
import Chessboard from '@/components/Chessboard/Chessboard.vue';
import ChessboardToolbar from '@/components/Chessboard/ChessboardToolbar.vue';
import rankList from '@/json/rank.json';
import ModalResult from '@/components/Modal/ModalResult.vue';
import PassAnimation from '@/components/Base/PassAnimation.vue';
import WebPassAnimation from '@/components/Base/WebPassAnimation.vue';
import BaseHeader from '@/components/Base/BaseHeader';
import tsumeGameService from '@/services/tsumeGame.js';
import verificationService from '@/services/verification.js';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';
import TsumeAnswerList from '@/components/Practice/TsumeAnswerList.vue';
import TsumeCheckAnswer from '@/components/Practice/TsumeCheckAnswer.vue';
import Video from '@/components/Video/Video.vue';
import TsumePracticeList from '@/views/Practice/TsumePracticeList.vue';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import Setting from '@/views/Personal/RoomSetting.vue';
import {getTsumego} from '@/lib/api/getStatic';
import delay from '@/lib/base/delay.js';
import _ from 'lodash';
import BackEvent from '@/lib/base/backEvent.js';
import day from 'dayjs';

export default {
  name: 'TsumeRoom',
  components: {
    Chessboard,
    ChessboardToolbar,
    ModalResult,
    PassAnimation,
    WebPassAnimation,
    BaseHeader,
    ModalMessageBox,
    TsumeAnswerList,
    TsumeCheckAnswer,
    Video,
    TsumePracticeList,
    ModalWrapper,
    Setting,
  },
  props: {
    mode: {
      type: String,
      default: 'practiceTsume',
      validator: (val) => {
        return ['practiceTsume', 'courseTsume', 'verificationTsume'].includes(
          val
        );
        // 練習、課程、檢定
      },
    },
  },
  data() {
    return {
      isCommentaryVideoShow: false,
      isPassAnimation: false,
      isLeave: false,
      chessboardKey: 0,
      questions: null,
      question: null,
      questionIndex: 0,
      questionTitle: '',
      createdTime: null,
      isTsumeStart: false,
      isQuestionNext: false,
      questionsCorrectCount: 0,
      isWin: false,
      rankList,
      isModalTsumeResultShow: false,
      isModalMessageBoxShow: false,
      tsumeData: {
        boardSize: 9,
        sgf: '(;CA[big5]SZ[9])',
      },
      messageBoxButtons: [
        {
          variant: 'primary',
          value: 'confirm',
          content: '知道了',
        },
      ],
      isTsumeAnswerShow: false,
      isTsumeCheckAnswerShow: false,
      selectedQuestion: 0,
      videoId: '',
      timer: null,
      remainTime: '',
      overTime: 0,
      isOverTime: false,
      questionTitleHeight: 0,
      chessboardToolbarMode: '',
      tsumeRoomChessboardMode: 'tsume',
      selectedQuestionTab: 'myAnswer',
      isSettingShow: false,
      isPlayerLocked: false,
      willStartPracticeTsume: false,
    };
  },
  computed: {
    type() {
      if (this.mode === 'verificationTsume') {
        return `${this.$route.params.type}`;
      }
      return this.$route.params.type;
    },
    rank() {
      return this.$route.params.rank;
    },
    hasPracticeTsumeList() {
      return (
        (this.mode === 'practiceTsume' &&
          this.type === 'uncast' &&
          this.rank === 'uncast') ||
        !this.permission?.tsume
      );
    },
    title() {
      if (this.mode === 'practiceTsume') {
        return this.$t('做題');
      } else if (this.mode === 'courseTsume') {
        return this.$t('課程－做題');
      } else {
        return this.$t('檢定－做題');
      }
    },
    questionsCount() {
      return this.questions ? this.questions.length : 1;
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    courseUniqueKey() {
      return this.$route.params.courseUniqueKey;
    },
    baseHeaderLeftText() {
      if (!this.$device.isMobile) {
        return this.mode === 'verificationTsume'
          ? this.$t('返回上一頁')
          : this.$t('返回首頁');
      } else {
        return '';
      }
    },
    permission() {
      return this.$store.getters['env/permission'];
    },
    isFinal() {
      return (
        this.questions.filter(
          (question) => typeof question.isCorrect === 'undefined'
        ).length === 1
      );
    },
  },
  watch: {
    questionTitleHeight(newValue) {
      if (newValue) {
        const twoLineHeight = 28 * 2;
        if (newValue > twoLineHeight) {
          this.$refs.questionTitle.classList.add('overLine');
        } else {
          this.$refs.questionTitle.classList.remove('overLine');
        }
      }
    },
    '$route.params'() {
      if (this.willStartPracticeTsume) {
        this.willStartPracticeTsume = false;
        this.startPracticeTsume();
      }
    },
  },
  async created() {
    this.createBeforeunloadHandler();
    if (this.mode === 'verificationTsume') {
      this.overTime = this.$route.params.overTime;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async mounted() {
    if (this.hasPracticeTsumeList) return;
    const reloadTsume = JSON.parse(localStorage.getItem('reloadTsume'));

    if (reloadTsume) {
      this.$store.commit('env/setIsLoading', true);
      if (this.mode === 'courseTsume') {
        this.getUnfinishedCourseTsume().then(async (result) => {
          if (!result) return;
          this.isPassAnimation = true;
          await delay(1000);
          this.questions = result;
          this.getTsumeQuestion();
          this.isLeave = true;
        });
      } else if (this.mode === 'practiceTsume') {
        this.isPassAnimation = true;
        Promise.all([this.getTsumeQuestions(), delay(1000)]).then((result) => {
          this.questions = result[0];
          this.createdTime = this.questions[0].createdTime;
          this.getTsumeQuestion();
          this.isLeave = true;
        });
      } else if (this.mode === 'verificationTsume') {
        this.isPassAnimation = true;
        Promise.all([this.getVerifiaction(), delay(1000)]).then(async () => {
          const tsume = this.getVerifiactionTsume();
          this.$store.commit('env/setIsLoading', false);
          this.questions = tsume.questions.map((question) => {
            return {...question, ...question.record};
          });
          this.createdTime = this.questions[0].createdAt;
          await delay(1000);
          this.getTsumeQuestion();
          this.isLeave = true;
        });
      }
    } else {
      localStorage.setItem('reloadTsume', true);
      if (!this.$device.isMobile) this.isPassAnimation = true;
      if (this.mode === 'courseTsume') {
        this.questions = await this.getUnfinishedCourseTsume();
      } else if (this.mode === 'practiceTsume') {
        this.questions = await this.getTsumeQuestions();
      } else if (this.mode === 'verificationTsume') {
        this.questions = await this.$store.getters[
          'tsumeGame/verificationTsume'
        ].questions;
        this.questions = this.questions.map((question) => {
          return {...question, ...question.record};
        });
      }
      if (this.questions) {
        this.createdTime = this.questions[0].createdAt;
      }
      this.getTsumeQuestion();
      if (!this.$device.isMobile) {
        await delay(300);
        this.isLeave = true;
      }
    }
    if (this.mode === 'verificationTsume') {
      this.setVerificationTimer();
    }
  },
  destroyed() {
    localStorage.setItem('reloadTsume', false);
    this.destroyedBeforeunloadHandler();
  },
  methods: {
    async getTsumeQuestions() {
      const randomTsumePractice = await tsumeGameService.getRandomTsumePractice(
        this.rank,
        this.type
      );
      this.$store.commit('env/setIsLoading', false);
      return randomTsumePractice;
    },
    async createCourseTsume() {
      const courseUniqueKey = this.$route.params.courseUniqueKey;
      const courseTsume = await tsumeGameService.createCourseTsume(
        courseUniqueKey
      );
      this.$store.commit('env/setIsLoading', false);
      return courseTsume;
    },
    async getUnfinishedCourseTsume() {
      const courseUniqueKey = this.$route.params.courseUniqueKey;
      try {
        const unfinishedCourseTsume =
          await tsumeGameService.getUnfinishedCourseTsume(courseUniqueKey);
        const promises = unfinishedCourseTsume.map((question) => {
          if (question.sgf) {
            return {
              tsumeId: question.tsumeId,
              sgf: question.sgf,
            };
          }
          const tsumeId = encodeURIComponent(question.tsumeId);
          return getTsumego(tsumeId).then((sgf) => {
            return {
              tsumeId,
              sgf,
            };
          });
        });
        this.$store.commit('env/setIsLoading', false);
        const questions = await Promise.all(promises);
        return _.merge(unfinishedCourseTsume, questions);
      } catch {
        this.$store.commit('env/setIsLoading', false);
        this.$router.push('/');
      }
    },
    async getCourseTsumeResult() {
      const courseUniqueKey = this.$route.params.courseUniqueKey;
      const courseTsumeResult = await tsumeGameService.getCourseTsumeResult(
        courseUniqueKey
      );
      this.$store.commit('env/setIsLoading', false);
      return courseTsumeResult;
    },
    getTsumeQuestion() {
      const _questions = this.questions.map((question) => {
        const prompt = this.getPromptFromSgf(question.sgf);
        if (this.mode === 'verificationTsume' || !question.category) {
          question.category = this.getTypeFromSgf(question.sgf);
        }
        return {
          tsumeId: question.id,
          sgf: question.sgf,
          isCorrect: question.isCorrect,
          type: question.type ? question.type : this.type,
          rank: question.rank || this.verificationData.rank,
          prompt:
            this.mode === 'verificationTsume' ? prompt : this.$t(`${prompt}`),
          userAnswer: question.userAnswer,
          category: question.category,
          tsumeMode: question.tsumeMode,
          videoId: question.videoId,
        };
      });
      this.questions = _questions;
      if (this.mode === 'verificationTsume') {
        this.questions.forEach((question) => {
          if (question.isCorrect) this.questionsCorrectCount++;
        });
      }
      this.nextQuestion();
      this.isTsumeStart = true;
    },
    getPromptFromSgf(sgf) {
      const gameNameRegexMatch = sgf.match(new RegExp('GN\\[(.*?)\\]', 'i'));
      const gameName = gameNameRegexMatch && gameNameRegexMatch[1];
      if (gameName) {
        return gameName;
      }
      const gameCommentRegexMatch = sgf.match(new RegExp('GC\\[(.*?)\\]', 'i'));
      const gameComment = gameCommentRegexMatch && gameCommentRegexMatch[1];
      if (gameComment) {
        return gameComment;
      }

      const commentRegexMatch = sgf.match(new RegExp('C\\[(.*?)\\]', 'i'));
      return commentRegexMatch && commentRegexMatch[1];
    },
    getTypeFromSgf(sgf) {
      const typeCommentRegexMatch = sgf.match(
        new RegExp('TYPE\\[(.*?)\\]', 'i')
      );
      return typeCommentRegexMatch && typeCommentRegexMatch[1];
    },
    async updateTsumeQuestion({isCorrect, userAnswer}) {
      this.isQuestionNext = false;
      if (isCorrect) this.questionsCorrectCount++;

      if (this.mode === 'courseTsume') {
        Promise.all([
          tsumeGameService.updateTsumeCourse(
            this.question.tsumeId,
            this.question.tsumeMode,
            userAnswer,
            isCorrect,
            this.courseUniqueKey,
            this.isFinal
          ),
          delay(700),
        ]).then(() => {
          this.question.isCorrect = isCorrect;
          this.question.userAnswer = userAnswer;
          this.nextQuestion();
        });
      } else if (this.mode === 'practiceTsume') {
        const coursePlan = this.currentPlan.id;

        Promise.all([
          tsumeGameService.updateTsumePractice(
            this.question.tsumeId,
            this.question.type,
            this.question.rank,
            userAnswer,
            isCorrect,
            coursePlan,
            this.isFinal
          ),
          delay(700),
        ]).then(() => {
          this.question.isCorrect = isCorrect;
          this.question.userAnswer = userAnswer;
          this.nextQuestion();
        });
      } else if (this.mode === 'verificationTsume') {
        Promise.all([
          verificationService.updateTsumeVerificate(
            this.verificationData.rank,
            this.question.tsumeId,
            this.verificationData.id,
            this.question.type,
            isCorrect,
            userAnswer
          ),
          delay(700),
        ]).then(() => {
          this.question.isCorrect = isCorrect;
          this.question.userAnswer = userAnswer;
          this.nextQuestion();
        });
      }
    },
    nextQuestion() {
      this.question = this.questions.find(
        (question) => typeof question.isCorrect === 'undefined'
      );
      this.questionIndex = this.questions.indexOf(this.question);
      if (this.questionIndex === -1) {
        this.tsumeOverGetResult();
        this.questionIndex = this.questions.length - 1;
      } else {
        this.questionTitle = this.question.prompt;
        this.isQuestionNext = true;
      }
      this.$nextTick(() => {
        this.questionTitleHeight = this.$refs.questionTitle.scrollHeight;
      });
    },
    async tsumeOverGetResult() {
      this.$store.commit('env/setIsLoading', true);
      this.remainTime = '00:00';
      let courseTsumeResult;
      if (this.mode === 'courseTsume') {
        courseTsumeResult = await this.getCourseTsumeResult();
        this.questionsCorrectCount = courseTsumeResult.correctAmount;
      }
      this.isWin = this.questionsCorrectCount / this.questionsCount >= 0.7;
      if (this.mode === 'verificationTsume') {
        this.$playSound('finishVerification');
      } else {
        this.$playSound(this.isWin ? 'win' : 'lose');
      }
      const questionsErrorCount =
        this.questionsCount - this.questionsCorrectCount;
      this.tsumeReslut = `${this.questionsCorrectCount}${this.$t(
        '題正確'
      )} ${questionsErrorCount}${this.$t('題錯誤')}`;
      this.$store.commit('env/setIsLoading', false);
      BackEvent.addEvent(() => {
        this.goBack();
      });

      this.isModalTsumeResultShow = true;
    },
    async resetTsume() {
      this.chessboardKey = 0;
      this.question = null;
      this.questionIndex = null;
      if (this.questions) {
        this.createdTime = this.questions[0].createdAt;
      }
      this.questionsCorrectCount = 0;
      this.getTsumeQuestion();
      await delay(1000);
      this.isLeave = true;
    },
    getVerifiaction() {
      return this.$store.dispatch(
        'verification/getVerification',
        this.currentPlan.id
      );
    },
    getVerifiactionTsume() {
      const id = this.$route.params.id;
      const type = this.$route.params.type;

      if (this.verificationData.id === id) {
        const questions = this.verificationData.tsume.find((tsume) => {
          return tsume.type === type;
        });
        return questions;
      } else {
        this.$router.push('/verification');
      }
    },
    // 對弈事件處理
    async onModalReultClick(value) {
      if (value === 'restart') {
        BackEvent.popEvent();
        this.isModalTsumeResultShow = false;
        if (this.$device.isMobile) {
          this.$store.commit('env/setIsLoading', true);
          this.isQuestionNext = false;
          this.questions = await this.getTsumeQuestions();
          this.isPassAnimation = true;
          await delay(500);
          this.resetTsume();
        } else {
          this.isQuestionNext = false;
          this.$refs.chessboard.loadSgf(this.tsumeData.sgf);
          this.$refs.chessboard.isCorrectModalShow = false;
          this.$router.push({
            name: 'practiceTsume',
            params: {rank: 'uncast', type: 'uncast'},
          });
        }
      } else if (value === 'restartLesson') {
        BackEvent.popEvent();
        this.isModalTsumeResultShow = false;
        this.$store.commit('env/setIsLoading', true);
        this.isQuestionNext = false;
        await this.createCourseTsume();
        this.questions = await this.getUnfinishedCourseTsume();
        this.isPassAnimation = true;
        await delay(500);
        this.resetTsume();
      } else if (value === 'answer') {
        this.$refs['wrap-tsume-room'].style.zIndex = 'auto';
        BackEvent.addEvent(() => {
          this.onCloseTsumeAnswerList();
        });
        this.$device.isMobile
          ? (this.isTsumeAnswerShow = true)
          : (this.isTsumeCheckAnswerShow = true);
        this.isModalTsumeResultShow = false;
      } else if (value === 'return' && this.mode === 'practiceTsume') {
        BackEvent.popEvent();
        if (this.$device.isMobile) {
          this.$router.push('/practice/tsumelist');
        } else {
          this.isQuestionNext = false;
          this.$refs.chessboard.loadSgf(this.tsumeData.sgf);
          this.$refs.chessboard.isCorrectModalShow = false;
          this.$router.push({
            name: 'practiceTsume',
            params: {rank: 'uncast', type: 'uncast'},
          });
        }
        this.isModalTsumeResultShow = false;
        this.isModalMessageBoxShow = false;
      } else if (value === 'return' && this.mode === 'courseTsume') {
        BackEvent.popEvent();
        this.$router.push('/');
      } else if (value === 'confirm' && this.mode === 'verificationTsume') {
        BackEvent.popEvent();
        this.$router.push('/verification');
      }
    },
    onModalMessageBoxClick(value) {
      if (value === 'confirm' && this.mode === 'verificationTsume') {
        this.$router.push('/verification');
      }
    },
    async onCloseTsumeAnswerList() {
      BackEvent.popEvent();
      this.isTsumeAnswerShow = false;
      this.isModalTsumeResultShow = true;
      await delay(300);
      this.$refs['wrap-tsume-room'].style.zIndex = '';
    },
    onCloseTsumeCheckAnswer() {
      BackEvent.popEvent();
      this.isTsumeCheckAnswerShow = false;
    },
    onChessboardToolbarModeChange(chessboardToolbarMode) {
      this.chessboardToolbarMode = chessboardToolbarMode;
    },
    onPlayerLockChange(value) {
      this.isPlayerLocked = value;
    },
    async startPracticeTsume() {
      localStorage.setItem('reloadTsume', true);
      this.$store.commit('env/setIsLoading', true);
      this.questions = await this.getTsumeQuestions();
      this.isPassAnimation = true;
      this.resetTsume();
    },
    // about chessboardtoolbar
    onToolbarClick(event) {
      this.$refs.chessboard.onToolbarClick(event);
    },
    moveChess(direction) {
      this.$refs.chessboard.moveChess(direction);
    },
    destoryPassAnimation() {
      this.isPassAnimation = false;
      this.isLeave = false;
    },
    goBack() {
      BackEvent.clearEvents();
      if (this.mode === 'courseTsume') {
        this.$router.push('/');
      } else if (this.mode === 'practiceTsume') {
        return this.$device.isMobile
          ? this.$router.push('/practice/tsumelist')
          : this.$router.push('/');
      } else {
        this.isModalMessageBoxShow = true;
      }
    },
    createBeforeunloadHandler() {
      window.addEventListener('beforeunload', this.beforeunloadHandler);
    },
    destroyedBeforeunloadHandler() {
      window.removeEventListener('beforeunload', this.beforeunloadHandler);
    },
    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = '關閉提示';
      }
      return '關閉提示';
    },
    checkAnswer(index) {
      BackEvent.addEvent(() => {
        this.onCloseTsumeCheckAnswer();
      });
      this.selectedQuestion = index;
      if (this.$device.isMobile) {
        this.isTsumeCheckAnswerShow = true;
      }
    },
    onSelectedQuestionTab(value) {
      if (value === 'answer') {
        this.$refs.chessboard.checkAnswerTab('question');
        this.selectedQuestionTab = 'question';
      } else {
        this.$refs.chessboard.checkAnswerTab(value);
        this.selectedQuestionTab = value;
      }
    },
    OnClickAnswer() {
      this.$refs.chessboard.checkAnswerTab('answer');
    },
    goSetting() {
      return this.$device.isMobile
        ? this.$router.push({name: 'roomSetting'})
        : (this.isSettingShow = true);
    },
    goVideo(videoId) {
      BackEvent.addEvent(() => {
        this.closeVideo();
      });
      this.isCommentaryVideoShow = true;
      this.videoId = videoId;
    },
    async setVerificationTimer() {
      if (this.overTime === 0) {
        const apiData = this.$store.state.verification.currentVerificationTsume;
        this.overTime = await verificationService.startTsume(
          apiData.rank,
          apiData.id,
          apiData.type
        );
      }
      this.timer = setInterval(() => {
        this.tick();
      }, 1000);
    },
    tick() {
      if (this.overTime - Date.now() < 0) {
        this.tsumeOverGetResult();
        this.isOverTime = true;
        clearInterval(this.timer);
      } else {
        this.remainTime = day(this.overTime - Date.now()).format('mm:ss');
        if (this.overTime - Date.now() <= 60000) {
          this.$refs['header'].$el.style.background = '#f35d23';
        }
      }
    },
    closeVideo() {
      BackEvent.popEvent();
      this.isCommentaryVideoShow = false;
      this.$store.commit('env/setIsLoading', false);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.isModalTsumeResultShow ||
      this.isModalMessageBoxShow ||
      this.mode === 'courseTsume' ||
      this.mode === 'practiceTsume' ||
      to.name === 'roomSetting'
    ) {
      if (to.name !== 'roomSetting') {
        const key =
          this.$vnode.key == null
            ? this.$vnode.componentOptions.Ctor.cid +
              (this.$vnode.componentOptions.tag
                ? `::${this.$vnode.componentOptions.tag}`
                : '')
            : this.$vnode.key;
        const cache = this.$vnode.parent.componentInstance.cache;
        const keys = this.$vnode.parent.componentInstance.keys;
        if (cache[key]) {
          if (keys.length) {
            const index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          delete cache[key];
        }
        this.$destroy();
      }
      this.isModalMessageBoxShow = false;
      next();
      return;
    }
    next(false);
    this.goBack();
  },
};
</script>

<style lang="scss" scoped>
.wrap-tsume-room {
  background: $bgsection;
  .tsume-header {
    transition: all 0.3s;
  }
  .tsume-room {
    min-height: calc(100% - 56px);
    @media screen and (min-width: 768px) {
      max-width: 1440px;
      margin: 0 auto;
      min-height: calc(100% - 56px);
      max-height: calc(100vh - 56px);
      height: calc(100% - 56px);
    }
    &.isVerificationTsume {
      min-height: calc(100% - 56px - 18px);
    }
  }
  .wrap-right {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .wrap-operate-panel {
    width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 289px;
      width: 289px;
      margin: 0;
      height: 100%;
      border-radius: $rounded-md;
      padding: 16px;
    }
    @media screen and (min-width: 1440px) {
      min-width: 446px;
      width: 446px;
    }
    .operate-panel {
      @media screen and (min-width: 768px) {
        background: $bgsection;
        height: 100%;
        border-radius: 10px;
      }
      .wrap-victory-condition {
        @media screen and (min-width: 768px) {
          padding: 5px 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }
  .wrap-question {
    @media screen and (min-width: 768px) {
      padding-top: 6px;
    }
    .wrap-question-details {
      .question {
        font-size: 16px;
        @media screen and (min-width: 768px) {
          line-height: 28px;
          &.overLine {
            line-height: 20px;
            font-size: 12px;
          }
        }
        &.overLine {
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
  }
  .wrap-quection-count {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    color: white;
    @media screen and (min-width: 768px) {
      width: 56px;
      height: 56px;
      min-width: 56px;
      padding: 4px 6px;
    }
    .question-index {
      font-size: 20px;
      @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
      }
    }
    .question-count {
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .chessboard {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
  .video {
    top: 0;
    z-index: 1023;
    .back-btn-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100px;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0) 100%
      );
      .back-btn {
        z-index: 3;
        position: absolute;
        left: 16px;
        top: 16px;
        pointer-events: all;
        color: #fff;
        cursor: pointer;
        font-size: 26px;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
  // SLIDE_TRANSITION
  .slide-enter-active,
  .slide-leave-active {
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  }
  .slide-enter {
    transform: translate(100vw, 0);
  }
  .slide-leave-active {
    transform: translate(100vw, 0);
  }
}
</style>
