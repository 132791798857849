<template>
  <div class="wrap-tsume-answer-list h-100 overflow-hidden">
    <base-header
      :title="$t('題目列表')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="false"
      @on-click-left="goBack"
    ></base-header>
    <div
      class="tsume-answer-list scroll-bar overflow-auto pt-2 pb-4 pl-3"
      :class="device === 'ios' ? 'pr-3' : 'pr-2'"
    >
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="wrap-tsume-answer d-flex py-2 pl-3 justify-content-between align-items-center"
      >
        <div
          class="wrap-check-answer d-flex"
          :class="{'tsume-mode': mode === 'courseTsume'}"
          @click.stop="onClickQuestion(index)"
        >
          <div class="chessboard-panel">
            <div class="chessboard m-0 p-0 position-relative">
              <chessboard
                mode="board"
                :board-size="9"
                :sgf="question.sgf"
                toolbar="board"
              ></chessboard>
            </div>
          </div>
          <div
            class="tsume-content flex-fill d-flex flex-column justify-content-center align-items-start ml-3"
          >
            <p class="question-count m-0 text-16">
              {{ $t('第') }}{{ index + 1 }}{{ $t('題') }}：<span
                v-if="question.isCorrect"
                class="is-correct text-16"
                >{{ $t('正確') }}</span
              ><span v-else class="is-error text-16">{{ $t('錯誤') }}</span>
            </p>
            <p class="check-answer m-0 text-16">{{ $t('查看解答') }}</p>
          </div>
        </div>
        <div
          v-if="mode === 'courseTsume' && question.videoId"
          class="wrap-comment-video d-flex justify-content-center align-items-center pr-3"
          @click.stop="onClickVideo(question.videoId)"
        >
          <span class="icon-Circle-play"> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Chessboard from '@/components/Chessboard/Chessboard.vue';

export default {
  name: 'TsumeAnswerList',
  components: {
    BaseHeader,
    Chessboard,
  },
  props: {
    questions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: 'practiceTsume',
    },
  },
  data() {
    return {};
  },
  computed: {
    device() {
      return this.$store.state.env.device;
    },
  },
  methods: {
    goBack() {
      this.$emit('on-click-back');
    },
    onClickQuestion(index) {
      this.$emit('on-click-question', index);
    },
    onClickVideo(videoId) {
      this.$emit('on-click-video', videoId);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-tsume-answer-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1022;
  background: $bgcontainer;
  .tsume-answer-list {
    height: calc(100% - 56px);
  }
  .wrap-tsume-answer {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 8px;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
    .wrap-check-answer {
      width: 100%;
      &.tsume-mode {
        width: calc(100% - 44px);
      }
    }
    .chessboard-panel {
      width: 84px;
      height: 84px;
      .wrapper {
        width: 84px;
        height: 84px;
        margin: 0 auto;
      }
    }
    .tsume-content {
      .is-correct {
        color: $positive;
      }
      .is-error {
        color: $negative;
      }
      .question-count {
        color: $font-grayscale-1;
        line-height: 1.75;
      }
      .check-answer {
        color: $font-grayscale-2;
        text-decoration: underline;
        line-height: 1.75;
      }
    }
    .wrap-comment-video {
      width: 44px;
      height: 84px;
      .icon-Circle-play {
        font-size: 32px;
        color: $primary;
      }
    }
  }
}
</style>
