import axios from 'axios';
import url from './url';

/**
 * 取得靜態檔案
 * @param {String} path 檔案在cdn的路徑
 * @param {Object} config axios設定
 * @param {Number} retryTimes 重送次數
 * @returns {Promise<Object>} API回傳結果
 */
function getStatic(path, config = {}, retryTimes = 0) {
  const apiUrl = `${url.cdn}${path}`;
  return axios.get(apiUrl, config).catch((error) => {
    console.error('getStatic error', error);
    if (retryTimes > 2) {
      console.error('getStatic fail!', error);
    } else if (error) {
      return delay(25).then(() => {
        return getStatic(path, config, retryTimes + 1);
      });
    }
    return Promise.reject(error);
  });
}

/**
 * 取得詰棋檔案
 * @param {String} tsumegoId 詰棋id
 * @returns {Promise<Object>} API回傳結果
 */
async function getTsumego(tsumegoId) {
  const result = await getStatic(`/tsumego/sgf/${tsumegoId}.sgf`);
  return result.data;
}

/**
 * 包裹setTimeout的工具函式
 * @param {Number} delayMs delay的毫秒數
 * @returns {Promise} API回傳結果
 */
function delay(delayMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });
}

export {getStatic, getTsumego};
